import { Controller } from "stimulus"
import Rails from "@rails/ujs";

export default class extends Controller {

  static targets = ["link", "load_url"]

  toggle_selected(){
    document.querySelectorAll(".task-detail").forEach(function(element){
      element.classList.remove("active")
    })
    this.element.querySelector(".task-detail").classList.add("active")
    document.querySelector("#load-template-button").setAttribute("href", this.load_urlTarget.value)
  }

  show_preview(event){
    const template_id = event.currentTarget.getAttribute("data-template-id")
    console.log(template_id)
    jQuery("#load-templates-modal").modal('hide')
    jQuery(`#template-preview-${template_id}`).modal('show')
  }

  delete_template(event){
    const controller = this
    jQuery("#load-templates-modal").modal('toggle')
    swal({
      title: 'Are you sure?',
      text: "Removing cannot be undone",
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete my template',
      cancelButtonText: 'Cancel',
      confirmButtonClass: 'btn btn-lg btn-danger waves-effect waves-light m-t-20',
      cancelButtonClass: 'btn btn-lg btn-transparent waves-effect waves-light m-l-10 m-t-20',
      buttonsStyling: false
    }).then(function () {
        controller.linkTarget.click()
        jQuery("#load-templates-modal").modal('toggle')

    }, function (dismiss) {
        if (dismiss === 'cancel') {
          jQuery("#load-templates-modal").modal('toggle')
        }
    })
  }
  

}
