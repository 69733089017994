import { Controller } from "stimulus"
import heic2any from 'heic2any';


export default class extends Controller {

  static targets = [
    "position_option",
    "position_option_button",
    "edge_option",
    "edge_option_button",
    "mode_option_button",
    "mode_option",
    "logo_picker",
    "background_picker"
  ]

  initialize(){
    jQuery('#mainframe').attr("style", "overflow:hidden;min-height:" + (jQuery(window).height() - jQuery('#topnav').height()) + "px;")
    jQuery(window).on('resize', function(){
      jQuery('#mainframe').attr("style", "overflow:hidden;min-height:" + (jQuery(window).height() - jQuery('#topnav').height()) + "px;")
    })
    this.business_name = document.getElementById("user_businessname").value
    this.logo_url = document.getElementById("logo-picker").getAttribute("data-default-file")
    this.logo_size = document.getElementById("user_logo_size").value
    if (document.getElementById("user_logo_position_left").checked){
      this.logo_position = "left"
    } else if (document.getElementById("user_logo_position_right").checked) {
      this.logo_position = "right"
    } else {
      this.logo_position = "center"
    }
    this.container_colour = document.getElementById("user_bgrgba").value
    this.container_opacity = (document.getElementById("user_bgopacity").value / 100).toFixed(2)
    if (document.getElementById("user_container_edges_straight").checked){
      this.container_edges = "straight"
    } else if (document.getElementById("user_container_edges_rounded").checked){
      this.container_edges = "rounded"
    } else {
      this.container_edges = "circular"
    }
    this.font = $("#font_selector option:selected")[0].innerText;
    this.font_path = document.getElementById("font_selector").value
    this.text_colour = document.getElementById("user_texthex").value
    this.accent_colour = document.getElementById("user_secondaryhex").value
    this.mode = (document.getElementById("user_background_mode_image").checked ? "image" : "colour")
    this.background_image = document.getElementById("background-picker").getAttribute("data-default-file")
    this.background_colour = document.getElementById("user_background_color").value

    jQuery('#picker').colpick({
      layout:'rgbhex',
      submit:0,
      color: document.getElementById('user_bghex').value,
      colorScheme:'light',
      onChange:function(hsb,hex,rgb,el,bySetColor) {
        document.getElementById('picker').setAttribute('style', `background:#${hex}`)
        var rgbcompile = "rgba(" + rgb.r + "," + rgb.g + "," + rgb.b + ",1)"
        document.getElementById('user_bghex').setAttribute('value', hex)
        document.getElementById('user_bgrgba').setAttribute('value', rgbcompile)
        const evt = new Event('change')
        document.getElementById('user_bgrgba').dispatchEvent(evt)
        // Fill the text box just if the color was set using the picker, and not the colpickSetColor function.
        if(!bySetColor) jQuery(el).val(hex);
      }
    }).keyup(function(){
      jQuery(this).colpickSetColor(this.value);
    });

    jQuery('#text-picker').colpick({
      layout:'rgbhex',
      submit:0,
      color: document.getElementById('user_bghex').value,
      colorScheme:'light',
      onChange:function(hsb,hex,rgb,el,bySetColor) {
        jQuery('#text-picker').css('background', "#" + hex)
        document.getElementById('user_texthex').setAttribute("value", hex)
        const evt = new Event('change')
        document.getElementById('user_texthex').dispatchEvent(evt)
        // Fill the text box just if the color was set using the picker, and not the colpickSetColor function.
        if(!bySetColor) jQuery(el).val(hex)
      }
    }).keyup(function(){
      jQuery(this).colpickSetColor(this.value)
    })

    jQuery('#secondary-picker').colpick({
      layout:'rgbhex',
      submit:0,
      color: document.getElementById('user_secondaryhex').value,
      colorScheme:'light',
      onChange:function(hsb,hex,rgb,el,bySetColor) {
        jQuery('#secondary-picker').css('background', "#" + hex)
        var rgbcompile = "rgba(" + rgb.r + "," + rgb.g + "," + rgb.b + ",1)"
        document.getElementById("user_secondaryhex").setAttribute("value", hex)
        document.getElementById("user_secondaryrgba").setAttribute("value", rgbcompile)
        const evt = new Event('change')
        document.getElementById('user_secondaryhex').dispatchEvent(evt)
        // Fill the text box just if the color was set using the picker, and not the colpickSetColor function.
        if(!bySetColor) jQuery(el).val(hex)
      }
    }).keyup(function(){
      jQuery(this).colpickSetColor(this.value)
    })

    jQuery('#background-color-picker').colpick({
      layout:'rgbhex',
      submit:0,
      color: document.getElementById('user_background_color').value,
      colorScheme:'light',
      onChange:function(hsb,hex,rgb,el,bySetColor) {
        document.getElementById('background-color-picker').setAttribute('style', `background:#${hex}`)
        //var rgbcompile = "rgba(" + rgb.r + "," + rgb.g + "," + rgb.b + ",1)"

        document.getElementById('user_background_color').setAttribute('value', `#${hex}`)
        const evt = new Event('change')
        document.getElementById('user_background_color').dispatchEvent(evt)
        // Fill the text box just if the color was set using the picker, and not the colpickSetColor function.
        if(!bySetColor) jQuery(el).val(hex)
      }
    }).keyup(function(){
      jQuery(this).colpickSetColor(this.value)
    })
  }

  update_business_name(){
    this.business_name = document.getElementById("user_businessname").value
    this.update_preview()
  }

  update_logo_size() {
    this.logo_size = document.getElementById("user_logo_size").value
    this.update_preview()
  }

  update_login_event_mode() {
    this.login_event_mode = document.getElementById("user_login_event_mode").checked
    this.update_preview()
  }

  update_edges(event){
    event.stopPropagation()
    this.edge_optionTargets.forEach(option => option.removeAttribute("checked"))
    const id = event.currentTarget.getAttribute("for")
    document.getElementById(id).setAttribute("checked", "checked")
    this.edge_option_buttonTargets.forEach(button => {
      if (button == event.currentTarget) {
        button.classList.add("selected")
      } else {
        button.classList.remove("selected")
      }
    })
    this.container_edges = document.getElementById(id).value
    this.update_preview()
  }

  update_container_opacity(){
    this.container_opacity = (document.getElementById("user_bgopacity").value / 100).toFixed(2)
    this.update_preview()
  }

  update_container_colour(){
    this.container_colour = document.getElementById("user_bgrgba").getAttribute('value')
    this.update_preview()
  }

  update_position(event){
    event.stopPropagation()
    this.position_optionTargets.forEach(option => option.removeAttribute("checked"))
    const id = event.currentTarget.getAttribute("for")
    document.getElementById(id).setAttribute("checked", "checked")
    this.position_option_buttonTargets.forEach(button => {
      if (button == event.currentTarget) {
        button.classList.add("selected")
      } else {
        button.classList.remove("selected")
      }
    })
    this.logo_position = document.getElementById(id).value
    this.update_preview()
  }

  async update_logo_image(event){
    const file = event.currentTarget.files[0];
    this.logo_url = URL.createObjectURL(file)
    const fileInput = this.logo_pickerTargets;
    if (file && file.type === 'image/heic') {
      try {
        const convertedBlob = await heic2any({ blob: file, toType: 'image/jpeg' });
        const newFile = new File([convertedBlob], file.name.replace(/\.heic$/i, '.jpg'), { type: 'image/jpeg' });
        const reader = new FileReader();
        reader.onload = (e) => {
          this.logo_url = e.target.result;  // Set the data URL as update_logo_image
          this. update_dropify_preview(this.logo_url, event, '96px');
          this.update_preview();  // Call update_preview to update the preview
        };
        reader.readAsDataURL(newFile);
      } catch (error) {
        console.error('HEIC to JPEG conversion error:', error);
      }
    } else {
      // For non-HEIC files, directly update the background image
      const reader = new FileReader();
      reader.onload = (e) => {
        this.logo_url = e.target.result;  // Set the data URL as logo_image
        this.update_preview();  // Call update_preview to update the preview
      };
      reader.readAsDataURL(file);
    }
  }


  select_font_mousedown(event){
    event.preventDefault()
    jQuery(event.currentTarget).blur()
    window.focus()
  }

  select_font_mouseup(event){
    const font_button = document.getElementById("font_selector_dropdown")
    font_button.setAttribute("style", font_button.getAttribute("style").replace("none", "block"))
    jQuery("#font_selector_dropdown").click(function(e) {
      e.stopPropagation()
    })
    jQuery("#font_selector").on('click', function(e) {
      e.stopPropagation()
    })
    jQuery(document.body).click(function(){
      jQuery(".dropdown-menu").hide()
      jQuery(document.body).off('click')
    })
  }

  font_option(event){
    const font_path = event.currentTarget.getAttribute("data-path")
    const font = event.currentTarget.innerText
    jQuery("#font_selector").val(font_path)
    jQuery(".dropdown-menu").hide()
    jQuery(document.body).off('click')
    this.font = font
    this.font_path = font_path
    this.update_preview()
  }

  update_text_colour(){
    this.text_colour = document.getElementById("user_texthex").value
    this.update_preview()
  }

  update_accent_colour(){
    this.accent_colour = document.getElementById("user_secondaryhex").value
    this.update_preview()
  }

  update_mode(event) {
    event.stopPropagation()
    this.mode_optionTargets.forEach(option => option.removeAttribute("checked"))
    const id = event.currentTarget.getAttribute("for")
    document.getElementById(id).setAttribute("checked", "checked")
    this.mode_option_buttonTargets.forEach(button => {
      if (button == event.currentTarget) {
        button.classList.add("selected")
      } else {
        button.classList.remove("selected")
      }
    })
    if (id == "user_background_mode_image") {
      document.getElementById("background-image-selector").setAttribute("style", "display:block;")
      document.getElementById("background-colour-selector").setAttribute("style", "display:none;")
      this.mode = "image"
    } else {
      document.getElementById("background-image-selector").setAttribute("style", "display:none;")
      document.getElementById("background-colour-selector").setAttribute("style", "display:block;")
      this.mode = "colour"
    }
    this.update_preview()
  }

  update_background_colour(){
    this.background_colour = document.getElementById("user_background_color").value
    this.update_preview()
  }

  // update_background_image(event){
  //   this.background_image = URL.createObjectURL(event.currentTarget.files[0])
  //   this.update_preview()
  // }

  async update_background_image(event) {
    const file = event.currentTarget.files[0];
    this.background_image = URL.createObjectURL(file)
    const fileInput = this.background_pickerTargets;

    if (file && file.type === 'image/heic') {
      try {
        const convertedBlob = await heic2any({ blob: file, toType: 'image/jpeg' });
        const newFile = new File([convertedBlob], file.name.replace(/\.heic$/i, '.jpg'), { type: 'image/jpeg' });

        const reader = new FileReader();
        reader.onload = (e) => {
          this.background_image = e.target.result;
          this.update_dropify_preview(this.logo_url, event, '88px');
          this.update_preview();
        };
        reader.readAsDataURL(newFile);
      } catch (error) {
        console.error('HEIC to JPEG conversion error:', error);
      }
    } else {
      // For non-HEIC files, directly update the background image
      const reader = new FileReader();
      reader.onload = (e) => {
        this.background_image = e.target.result;
        this.update_preview();
      };
      reader.readAsDataURL(file);
    }
  }


  update_powered_by(){
    this.powered_by_beambox = document.getElementById("checkbox2").checked
    this.update_preview()
  }

  update_dropify_preview(url, event, maxHeight) {
    const dropifyRender = event.srcElement.nextElementSibling.querySelector('.dropify-render');
    if (dropifyRender) {
      const imgElement = document.createElement('img');
      imgElement.src = url;
      imgElement.style.maxHeight = maxHeight;
      dropifyRender.innerHTML = '';
      dropifyRender.appendChild(imgElement);
    }
  }

  update_preview(){
    const content = document.getElementById("mainframe").contentWindow.document
    content.querySelector(".logo").setAttribute("style", `width:${this.logo_size}%;margin-left:${this.logo_position == "left" ? 0 : "auto"};margin-right:${this.logo_position == "right" ? 0 : "auto"};`)
    content.querySelector(".logo").setAttribute("src", this.logo_url)
    content.querySelector(".main-cont").setAttribute("style", `background: ${this.container_colour.replace('1)', this.container_opacity + ")")};border-radius:${this.radius_calculator()}px;`)
    content.querySelector("body style").append('@font-face {font-family: "' + this.font + '"; src: url("https://wifiuploads.s3.amazonaws.com/' + this.font_path + '");}'); // include font in iframe.
    jQuery(content).find("body, h1, p").css('font-family', this.font)
    jQuery(content).find("h1, p, .description").css('color', `#${this.text_colour}`)
    jQuery(content).find(".form-item.active").css('border-left', ('3px solid #' + this.accent_colour))
    jQuery(content).find(".submit-button button").css('background', ('#' + this.accent_colour))

    if (this.mode == "image") {
      jQuery(content).find(".mask-bg").attr('style', `background:url('${this.background_image}');background-repeat:no-repeat;background-size:cover;background-position:center center;`)
    } else {
      jQuery(content).find(".mask-bg").attr('style', `background:${this.background_colour};`)
    }
    if (this.login_event_mode){
      jQuery(content.querySelector("#login-event-overlay")).addClass('snow-bg')
    } else {
      jQuery(content.querySelector("#login-event-overlay")).removeClass('snow-bg')
    }
  }

  radius_calculator(){
    if (this.container_edges == "straight"){
      return 0;
    } else if (this.container_edges == "rounded"){
      return 3
    } else {
      return 15
    }
  }

}
