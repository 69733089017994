// app/javascript/controllers/banner_controller.js

import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['banner'];

  connect() {
    console.log('Banner Initialsed...')
  }

  close(event) {
    event.preventDefault();

    var topNav = document.getElementById('topnav');
    var wrapper = document.querySelector('.wrapper');

    this.bannerTarget.style.transform = 'translateY(-100%)';
    this.bannerTarget.remove();

    const banners = document.querySelectorAll('.banner');

    if (banners.length === 0) {
      const marginTop = window.innerWidth <= 768 ? '130px' : '90px';
      wrapper.style.marginTop = marginTop;
    }
  }
}
