import { Controller } from "stimulus"

export default class extends Controller {

  static targets = ["filters"]

  initialize(){
    const params_array = this.filtersTarget.getAttribute("data-filters")
    this.renderFilters( JSON.parse(params_array) )
  }

  renderFilters(params_array){
    let text;
    let html;
    let pending_archive = false
    let cache_key = this.element.getAttribute("data-cache-key")
    let controller = this.filtersTarget.getAttribute("data-target-controller")
    let render_url = this.filtersTarget.getAttribute("data-render-url")

    if (params_array.length < 2 ) {
      $("#first-and").hide();
    } else {
      $("#first-and").show();
    }

    if (controller != "reviews--filter-reviews") {
      controller = "guest-database--filter-guests"
    }
    $.each(params_array, function( index, value ) {
      if ( value["filter_type"] == "archived_at"){
        if (value['search_value'] == 'has any value'){
          pending_archive = true
        }else{
          pending_archive = false
        }
        return true
      }
      else if ( value["filter_type"] == "location" ) {
        text = '<span class="search-field">' + value["search_field"] + "</span>" + " is " + $("#filter_option_" + value["search_value"]).closest('label').attr("data-location_name");
      } else if ( value["filter_type"] == "guest_tag" ) {
        text = '<span class="search-field">' + value["search_field"] + "</span>" + " is " + $("#filter_option_" + value["search_value"]).closest('label').attr("data-tag-name");
      } else if ( value["filter_type"] == "form" ) {
        text = '<span class="search-field">' + value["search_field"] + "</span>" + " is " + $("#filter_option_" + value["search_value"]).closest('label').attr("data-form_name");
      } else if ( value["filter_type"] == "guest_tag" ) {
        query_type = $("#filter-guest_tag [type=radio]:checked").val();
        search_value = query_type;
      } else if ( value["filter_type"] == "gender" ) {
        text = '<span class="search-field">' + value["search_field"] + "</span>" + " is " + value["search_value"];
      } else if ( value["filter_type"] == "provider" ) {
        text = '<span class="search-field">Login Method</span>' + " is " + value["search_value"];
      } else if ( value["filter_type"] == "rating") {
        text = '<span class="search-field">Rating</span>' + " is " + value["search_value"] + " stars";
      } else if ( value["filter_type"] == "review" ) {
        text = '<span class="search-field">Rating</span>' + " is " + value["search_value"] + " stars";
      } else if ( value["filter_type"] == "platform") {
        if ( ["google", "facebook", "tripadvisor", "yelp"].includes( value["search_value"] ) ) {
          text = '<span class="search-field">Platform</span>' + " is " + value["search_value"];
        } else {
          text = '<span class="search-field">Platform</span>' + " is Beambox";
        }

      } else if ( value["filter_type"] == "is_subscribed" ) {
        if (value["search_value"] == "true") {
          text = '<span class="search-field">Guest</span> is subscribed';
        } else {
          text = '<span class="search-field">Guest</span> is not subscribed';
        }

      } else if ( value["filter_type"] == "sms_subscribed" ) {
        if (value["search_value"] == "true") {
          text = '<span class="search-field">SMS</span> is subscribed';
        } else {
          text = '<span class="search-field">SMS</span> is not subscribed';
        }

      } else if ( ["name", "email", "phone", "visits", "first visit", "last visit", "birthday", "age", "nationality", "emails recieved", "emails opened", "emails clicked", "sms recieved", "loyalty score", "import file"].includes( value["search_field"] ) ) {
        if ( !["is unknown", "has any value"].includes( value["query_type"] ) ) {

          if ( ["first visit", "last visit"].includes( value["search_field"] ) ) {

            if ( value["query_type"] == "more than" ) {
              text = '<span class="search-field">' + value["search_field"] + '</span>' + " more than " + value["search_value"] + " days ago";
            } else if ( value["query_type"] == "more than hours" ) {
              text = '<span class="search-field">' + value["search_field"] + '</span>' + " more than " + value["search_value"] + " hours ago";
            } else if ( value["query_type"] == "exactly" ) {
              text = '<span class="search-field">' + value["search_field"] + '</span>' + " exactly " + value["search_value"] + " days ago";
            } else if ( value["query_type"] == "exactly hours" ) {
              text = '<span class="search-field">' + value["search_field"] + '</span>' + " exactly " + value["search_value"] + " hours ago";
            } else if ( value["query_type"] == "less than" ) {
              text = '<span class="search-field">' + value["search_field"] + '</span>' + " less than " + value["search_value"] + " days ago";
            } else if ( value["query_type"] == "less than hours" ) {
              text = '<span class="search-field">' + value["search_field"] + '</span>' + " less than " + value["search_value"] + " hours ago";
            }


          } else if ( value["search_field"] == "birthday" ) {

            if ( value["query_type"] == "more than" ) {
              text = '<span class="search-field">' + value["search_field"] + '</span>' + " more than " + value["search_value"] + " days ago";
            } else if ( value["query_type"] == "more than future" ) {
              text = '<span class="search-field">' + value["search_field"] + '</span>' + " more than " + value["search_value"] + " days from now";
            } else if ( value["query_type"] == "exactly" ) {
              text = '<span class="search-field">' + value["search_field"] + '</span>' + " exactly " + value["search_value"] + " days ago";
            } else if ( value["query_type"] == "exactly future" ) {
              text = '<span class="search-field">' + value["search_field"] + '</span>' + " exactly " + value["search_value"] + " days from now";
            } else if ( value["query_type"] == "less than" ) {
              text = '<span class="search-field">' + value["search_field"] + '</span>' + " less than " + value["search_value"] + " days ago";
            } else if ( value["query_type"] == "less than future" ) {
              text = '<span class="search-field">' + value["search_field"] + '</span>' + " less than " + value["search_value"] + " days from now";
            }

          } else {
            text = '<span class="search-field">' + value["search_field"] + "</span>" + " " + value["query_type"] + " " + value["search_value"];
          }

        } else {
          text = '<span class="search-field">' + value["search_field"] + "</span>" + " " + value["query_type"];
        }
      } else if ( value["filter_type"] == "blast" ) {
        text = '<span class="search-field">' + value["search_field"] + "</span>" + " is " + $("#filter_option_" + value["search_value"]).closest('label').attr("data-blast-name");
      } else if ( value["filter_type"] == "bounce-blast" ) {
        text = '<span class="search-field">' + value["search_field"] + "</span>" + " is " + $("#filter_option_" + value["search_value"]).closest('label').attr("data-blast-name");
      } else if ( value["filter_type"] == "complaint-blast" ) {
        text = '<span class="search-field">' + value["search_field"] + "</span>" + " is " + $("#filter_option_" + value["search_value"]).closest('label').attr("data-blast-name");
      } else if ( value["filter_type"] == "interaction" ) {
        text = '<span class="search-field">' + value["search_field"] + "</span>" + " is " + $("#filter_option_" + value["search_value"]).closest('label').attr("data-interaction-name");
      } else if ( value["filter_type"] == "bounce-interaction" ) {
        text = '<span class="search-field">' + value["search_field"] + "</span>" + " is " + $("#filter_option_" + value["search_value"]).closest('label').attr("data-interaction-name");
      } else if ( value["filter_type"] == "complaint-interaction" ) {
        text = '<span class="search-field">' + value["search_field"] + "</span>" + " is " + $("#filter_option_" + value["search_value"]).closest('label').attr("data-interaction-name");
      } else if ( value["filter_type"] == "is_archived" ) {
        if ( value["query_type"] == 'true' ){
          text = '<span class="search-field">Archived</span>' + " Guests";
        }else if (value["query_type"] == 'false' )  {
          if (pending_archive) {
            text = '<span class="search-field ">Pending Archived</span>' + " Guests";
          }
        }
      } else {
        if ( !["is unknown", "has any value"].includes( value["query_type"] ) ) {
          text = $('.filter[data-search-field="' + value["search_field"] + '"]')[0]?.innerText + " " + value["query_type"] + " " + value["search_value"];
          if ( value["filter_type"] == "date") {
            text += " days ago";
          }
        } else {
          text = $('.filter[data-search-field="' + value["search_field"] + '"]')[0]?.innerText + " " + value["query_type"];
        }
      }

      if( value["filter_type"] == "is_archived" ){
        html = `<div class="btn applied-filter" data-array-position=${index}><i data-feather="filter" stroke-width="2" width="14" height="14" class="m-r-5" style="vertical-align:middle;position:relative;top:-1px;"></i>${text}</div>`;
      }else{
        html = `<div data-controller=${controller} data-render-url=${render_url} data-action="click->${controller}#removeFilter" data-cache-key=${cache_key} class="btn applied-filter" data-array-position=${index}><i data-feather="filter" stroke-width="2" width="14" height="14" class="m-r-5" style="vertical-align:middle;position:relative;top:-1px;"></i>${text}<span class="remove-mask"><i data-feather="x" stroke-width="2" width="20" height="20" class="p-r-1 remove-mask-icon" ></i></span></div>`;
      }
      if (index > 1 && html != '') {
        html = '<span class="filter-text">and</span>'+html
      }
      $("#filter-summary").append(html);
    })
    $("[data-controller=render-filters]").remove();
    feather.replace();
  }

}
