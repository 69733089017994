import { Controller } from "stimulus"
import Rails from "@rails/ujs";

let swalConfirmed = false

export default class extends Controller {
  static targets = [

  ]

  initialize(){

  }

  removeTeamate(event){
    if(!swalConfirmed){
      event.stopPropagation()
      event.preventDefault()
      var id = event.currentTarget.getAttribute('data-id')

      swal({
        title: 'Are you sure?',
        text: "Removing cannot be undone",
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, remove this teammate!',
        cancelButtonText: 'Cancel',
        confirmButtonClass: 'btn btn-lg btn-danger waves-effect waves-light m-t-20',
        cancelButtonClass: 'btn btn-lg btn-transparent waves-effect waves-light m-l-10 m-t-20',
        buttonsStyling: false
      }).then(function () {
        swalConfirmed = true;
        $(".remove-trigger-" + id).trigger("click");
      }, function (dismiss) {
          if (dismiss === 'cancel') {
            // window.location.href = link;
          }
      });
    }
  }

  makeAccountOwner(event){
    if(!swalConfirmed){
      event.stopPropagation()
      event.preventDefault()
      var id = event.currentTarget.getAttribute("data-id")

      swal({
        title: 'Are you sure?',
        text: "You will no longer have control of this account",
        type: 'info',
        showCancelButton: true,
        confirmButtonText: 'Yes, change account owner!',
        cancelButtonText: 'Cancel',
        confirmButtonClass: 'btn btn-lg btn-primary waves-effect waves-light m-t-20',
        cancelButtonClass: 'btn btn-lg btn-transparent waves-effect waves-light m-l-10 m-t-20',
        buttonsStyling: false
      }).then(function () {
        swalConfirmed = true;
        $(".make-owner-trigger-" + id).trigger("click");
      }, function (dismiss) {
          if (dismiss === 'cancel') {
            // window.location.href = link;
          }
      });
    }
  }



}
