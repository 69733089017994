import { Controller } from "stimulus"
import Rails from "@rails/ujs";

export default class extends Controller {

  static targets = [
    "copy",
    "copy_modal"
  ]

  initialize(){
    this.add_plugins()

    $('#picker').colpick({
      layout:'rgbhex',
      submit:0,
      color: '<%= @user.bghex %>',
      colorScheme:'light',
      onChange:function(hsb,hex,rgb,el,bySetColor) {
        $('#picker').css('background', "#" + hex)
        $('#embeddable_form_accent_color').val(hex)
        console.log($('#embeddable_form_accent_color').val())
        document.querySelector('iframe').contentWindow.document.querySelector('#beambox-embeddable-form').setAttribute("style", "--accent_color:#" + $('#embeddable_form_accent_color').val() + "; --bg_color:#" + $('#embeddable_form_bg_color').val() + ";")

        if(!bySetColor) $(el).val(hex)
      }
    }).keyup(function(){
      $(this).colpickSetColor(this.value)
    })

    $('#bg-picker').colpick({
      layout:'rgbhex',
      submit:0,
      color: '<%= @user.bghex %>',
      colorScheme:'light',
      onChange:function(hsb,hex,rgb,el,bySetColor) {
        $('#bg-picker').css('background', "#" + hex)
        $('#embeddable_form_bg_color').val(hex)
        console.log($('#embeddable_form_bg_color').val())
        document.querySelector('iframe').contentWindow.document.querySelector('#beambox-embeddable-form').setAttribute("style", "--accent_color:#" + $('#embeddable_form_accent_color').val() + "; --bg_color:#" + $('#embeddable_form_bg_color').val() + ";")

        if(!bySetColor) $(el).val(hex)
      }
    }).keyup(function(){
      $(this).colpickSetColor(this.value)
    })



  }

  add_plugins(){
    let controller = this

    // jQuery('.sortable h4').editable({
    //   type: 'text',
    //   name: 'Field Name',
    //   title: 'Enter Field Name',
    //   mode: 'inline',
    //   success: function(response, newValue) {
    //     jQuery(this).closest(".form-field").attr('data-field-label', newValue)
    //     controller.compileJson()
    //   }
    // })

    jQuery(".sortable").sortable({
      connectWith: ".sortable",
      items: ".card-draggable",
      revert: !0,
      placeholder:"card-draggable-placeholder",
      forcePlaceholderSize: !0,
      opacity: .77,
      cursor:"move",
      stop: function(event, ui) {
        controller.compileJson()
      }
    })
  }

  compileJson(){
    var array = []
    var elements = $('#form-fields-modal .sortable .card-box');
    [].forEach.call(elements, function( el, index ) {
      var el_json = {}
      var field_index = (index + 1);
      var field_id = $(el).attr('data-field-id');
      var field_label = $(el).attr('data-field-label');
      var mode = $(el).attr('data-mode')

      el_json["index"] = field_index;
      el_json["id"] = field_id;
      el_json["label"] = field_label;
      el_json["mode"] = mode;
      array.push(el_json);
    });
    $("#embeddable_form_form_fields").val(JSON.stringify(array));
    this.renderPreview(array);
  }

  renderPreview(array){
    var html = ""
    array.forEach(function (e, index) {
      switch(e.id){
        case 'full_name':
          html += '<div class="beambox-form-item active"><label for="human_Full Name">' + e.label + '</label><input placeholder="" autocomplete="false" data-validetta="required,regExp[full_name]" type="text" name="human[full_name]" id="human_full_name"></div>'
        break;
        case 'email':
          html += '<div class="beambox-form-item"><label for="human_Email Address">' + e.label + '</label><input placeholder="" autocomplete="false" data-validetta="required,regExp[email_address],remote[validate_email]" type="text" name="human[email]" id="human_email"></div>'
        break;
        case 'gender':
          html += '<div class="beambox-form-item"><label for="human_Gender">' + e.label + '</label><div class="beambox-row collapse" id="gender-picker"><div class="beambox-small-12 beambox-columns beambox-select"><select data-validetta="required" name="human[gender]" id="human_gender"><option value="">Gender</option></select></div></div></div>'
        break;
        case 'phone_number':
          html += '<div class="beambox-form-item"><label for="human_Phone">' + e.label + '</label><div class="beambox-row " id="phone-picker"><div class="beambox-small-3 beambox-columns beambox-select"><select data-validetta="required" name="human[code]" id="human_code"><option selected="selected" value="+1">+1</option></select></div><div class="beambox-columns beambox-text"><input type="text" name="human[number]" id="human_number"></div><input autocomplete="false" type="hidden" name="human[phone]" id="human_phone"></div></div>'
        break;
        case 'country':
          html += '<div class="beambox-form-item"><label for="human_Country">' + e.label + '</label><div class="beambox-row beambox-collapse" id="country-picker"><div class="beambox-small-12 beambox-columns beambox-select"><select data-validetta="required" name="human[country]" id="human_country"><option value="">Country</option></select></div></div></div>'
        break;
        case 'date_of_birth':
          html += '<div class="beambox-form-item"><label for="human_Date of Birth">' + e.label + '</label><div class="beambox-row beambox-collapse" id="birthday-picker"><div class="beambox-small-3 beambox-columns beambox-select"><select data-validetta="required" name="human[day]" id="human_day"><option value="">Day</option></select></div><div class="beambox-columns beambox-select"><select data-validetta="required" name="human[month]" id="human_month"><option value="">Month</option></select></div><div class="beambox-small-4 beambox-columns beambox-select"><select data-validetta="required" name="human[year]" id="human_year"><option value="">Year</option></select></div></div><input autocomplete="false" type="hidden" name="human[birthday]" id="human_birthday"></div>'
        break;
        default:
          switch (e.mode){
            case "1":
              html += '<div class="beambox-form-item" data-preview-id="' + e.id + '"><label>' + e.label + '</label><div class="beambox-row"><input placeholder="" autocomplete="false" data-validetta="required,regExp[full_name]" type="text" name="human[full_name]" id="human_full_name"></div></div>'
            break;
            case "2":
              html += '<div class="beambox-form-item" data-preview-id="' + e.id + '"><label>' + e.label + '</label><div class="beambox-row"><input placeholder="" autocomplete="false" data-validetta="required,regExp[full_name]" type="number" name="human[full_name]" id="human_full_name"></div></div>'
            break;
            case "4":
              html += '<div class="beambox-form-item" data-preview-id="' + e.id + '"><label>' + e.label + '</label><div class="beambox-row"><input placeholder="" autocomplete="false" data-validetta="required,regExp[full_name]" type="date" name="human[full_name]" id="human_full_name"></div></div>'
            break;
            default:
              html += '<div class="beambox-form-item"><label for="human_Country">' + e.label + '</label><div class="beambox-row beambox-collapse" id="country-picker"><div class="beambox-small-12 beambox-columns beambox-select"><select data-validetta="required" name="human[country]" id="human_country"><option value=""></option></select></div></div></div>'
          }
      }
    })
    html += `<button type="submit" name="button">Subscribe</button><div class="beambox-disclaimer"><small>We respect your privacy. Unsubscribe at anytime.</small></div><div class="text-center"><a type="button" href="${window.BASE_URL}" class="power-copy" style="color: white; background: rgba(0,0,0,0.4);">Powered by Beambox</a></div>`
    $("iframe").contents().find("form").html(html)
  }


  select_field(event){
    jQuery("#form-fields-modal").modal('show')
    jQuery("#add-fields-modal").modal('hide')

    if ($("#embeddable_form_form_fields").val().indexOf($(event.currentTarget).parent().attr('data-field-id')) >= 0){
      toastr.warning("This field is already in your form.")
    } else {
      this.add_field(jQuery(event.currentTarget).parent().attr('data-field-id'), $(event.currentTarget).parent().attr('data-field-label'))
    }
  }

  add_field(id, label){
    jQuery(".sortable").append('<div class="card-box card-draggable form-field" data-field-id="' + id + '" data-field-label="' + label + '"><div class="card-body"><div class="row"><div class="col-sm-1 col-xs-1 text-center"><i class="zmdi zmdi-apps"></i></div><div class="col-sm-7 col-xs-5 text-left"><h4 class="card-title">' + label + '</h4></div><div class="col-sm-4 col-xs-6 text-right"><i class="zmdi zmdi-delete" data-controller="embeddable-forms--edit" data-action="click->embeddable-forms--edit#remove_field"></i></div></div></div></div>')
    this.add_plugins()
    this.compileJson()
  }

  add_new_field(){
    jQuery("#form-fields-modal").modal('hide')
  }

  remove_field(event){
    var element = jQuery(event.currentTarget).closest('.form-field')
    if (element.attr("data-field-id") == "email"){
      let valid = false
      document.querySelectorAll(".form-field").forEach(function(elem){

        if (elem.getAttribute("data-field-id") == "phone_number"){
          valid = true
        }
      })
      if (valid){
        var controller = this
        swal({
            title: 'Are you sure?',
            text: "This field will be removed",
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, remove it!',
            cancelButtonText: 'Cancel',
            confirmButtonClass: 'btn btn-lg btn-danger waves-effect waves-light m-t-20',
            cancelButtonClass: 'btn btn-lg btn-transparent waves-effect waves-light m-l-10 m-t-20',
            buttonsStyling: false
        }).then(function () {
          jQuery(element).remove()
          controller.compileJson()
        }, function (dismiss) {
          //
        })
      } else {
        toastr.error("You must include the phone number field to remove email")
      }
    } else if (element.attr("data-field-id") == "phone_number"){
      let valid = false
      document.querySelectorAll(".form-field").forEach(function(elem){
        if (elem.getAttribute("data-field-id") == "email"){
          valid = true
        }
      })
      if (valid){
        var controller = this
        swal({
            title: 'Are you sure?',
            text: "This field will be removed",
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, remove it!',
            cancelButtonText: 'Cancel',
            confirmButtonClass: 'btn btn-lg btn-danger waves-effect waves-light m-t-20',
            cancelButtonClass: 'btn btn-lg btn-transparent waves-effect waves-light m-l-10 m-t-20',
            buttonsStyling: false
        }).then(function () {
          jQuery(element).remove()
          controller.compileJson()
        }, function (dismiss) {
          //
        })
      } else {
        toastr.error("You must include the email field to remove phone number")
      }
    } else {
      var controller = this
      swal({
          title: 'Are you sure?',
          text: "This field will be removed",
          type: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, remove it!',
          cancelButtonText: 'Cancel',
          confirmButtonClass: 'btn btn-lg btn-danger waves-effect waves-light m-t-20',
          cancelButtonClass: 'btn btn-lg btn-transparent waves-effect waves-light m-l-10 m-t-20',
          buttonsStyling: false
      }).then(function () {
        jQuery(element).remove()
        controller.compileJson()
      }, function (dismiss) {
        //
      })
    }
  }

  rename_custom_field(event){
    jQuery("#rename-field-modal #custom_field_label").val($(event.currentTarget).attr('data-field-label'))
    jQuery("#rename-field-modal form").attr("action", (window.location.origin + '/app/' + $('body').attr('data-account-id') + '/form/field/' + $(event.currentTarget).attr('data-field-id')))

    jQuery("#add-fields-modal").modal('hide')
    jQuery("#rename-field-modal").modal('show')
  }

  add_custom_field(){
    jQuery("#add-fields-modal").modal('hide')
    jQuery("#new-field-modal").modal('show')
  }

  delete_custom_field(event){
    var id = $(event.currentTarget).attr('data-field-id').replace("custom_", "")
    swal({
      title: 'Are you sure?',
      text: "Removing cannot be undone",
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, remove this field!',
      cancelButtonText: 'Cancel',
      confirmButtonClass: 'btn btn-lg btn-danger waves-effect waves-light m-t-20',
      cancelButtonClass: 'btn btn-lg btn-transparent waves-effect waves-light m-l-10 m-t-20',
      buttonsStyling: false
    }).then(function () {
      $.ajax({
        url: (window.location.origin + '/app/' + $('body').attr('data-account-id') + '/form/field/' + id),
        type: 'DELETE',
        success: function(result) {
          // Do something with the result
        }
      })
    }, function (dismiss) {
      if (dismiss === 'cancel') {
        // window.location.href = link;
      }
    })
  }

  back_to_form_field(){
    jQuery("#form-fields-modal").modal('show')
    jQuery("#add-fields-modal").modal('hide')
    jQuery("#new-field-modal").modal('hide')
    jQuery("#rename-field-modal").modal('hide')
  }

  back_to_add_fields(){
    jQuery("#add-fields-modal").modal('show')
    jQuery("#new-field-modal").modal('hide')
    jQuery("#form-fields-modal").modal('hide')
    jQuery("#rename-field-modal").modal('hide')
  }

  copyToClipboard() {
    const copy = this.copyTarget;
    const originalDisplayStyle = copy.style.display;

    copy.style.display = 'block';
    copy.select();
    document.execCommand("copy");
    copy.style.display = originalDisplayStyle;

    if (this.hasCopy_modalTarget) {
      jQuery(this.copy_modalTarget).modal('toggle');
    }

    toastr.success("Saved to clipboard");
  }

  changeMode(event){
    const form = document.querySelector('form')
    const show_image = document.querySelector('#checkbox2').checked

    form.setAttribute("data-remote", true)

    let elements = document.getElementsByClassName('mode')
    for (var i = 0; i < elements.length; i++) {
       elements[i].classList.remove('selected');
       elements[i].querySelector("input").removeAttribute("checked")
    }
    event.currentTarget.classList.add('selected')
    event.currentTarget.querySelector("input").setAttribute("checked", true)

    if (event.currentTarget.querySelector("input").value == "Popup"){
      document.getElementById('image-control').setAttribute('style', 'display:block;')
      if (show_image) {
        document.getElementById('background-color').style.display = 'block'
      } else {
        document.getElementById('background-color').style.display = 'none'
      }

    } else {
      document.getElementById('image-control').setAttribute('style', 'display:none;')
      document.getElementById('background-color').style.display = 'none'
    }

    document.querySelector('button[type="submit"]').click()
    form.setAttribute("data-remote", false)

    setTimeout(function(){
      document.querySelector("iframe").contentWindow.location.reload()
    }, 800)
  }



  updateIframe(){

    const frame = document.querySelector("iframe").contentWindow.document

    let tmppath = document.getElementById("logo-picker").getAttribute("data-default-file")
    if (document.getElementById("logo-picker").value != ""){
      tmppath = URL.createObjectURL(document.getElementById("logo-picker").files[0])
    }
    const show_image = document.querySelector('#checkbox2').checked
    const text = document.getElementById("description-default").value
    frame.querySelector('.beambox-main-description').innerHTML = text
    if (show_image) {
      frame.querySelector('.beambox-img-cont').style.display = 'block'
      document.getElementById('background-color').style.display = 'block'
    } else {
      frame.querySelector('.beambox-img-cont').style.display = 'none'
      document.getElementById('background-color').style.display = 'none'
    }

    frame.querySelector('img').src = tmppath

  }



}
