import { Controller } from "stimulus"

export default class extends Controller {

  static targets = ["search_field", "filter_type", "search_value", "query_type", "humans_count", "segment", "segment_id"]

  initialize(){

    if (this.element.getAttribute("data-segment-selected") == "true") {
      this.element.classList.add('active')
      let segment = this.element.getAttribute("data-segment")
      let segment_id = this.element.getAttribute("data-segment_id")
      let segment_name = this.element.getAttribute("data-segment-name")
      let stateObj = { id: segment_id };
      let render_url = this.element.getAttribute("data-render-url")
      let cache_key = this.element.getAttribute("data-cache-key")
      window.history.pushState(stateObj, segment, window.location.href.split('?')[0] + "?segment_id=" + segment_id);
      let params_array = JSON.parse( this.element.getAttribute("data-segment-filters").replaceAll("=>", ":") )
      $(".segment-name").html( segment_name )
      $("#filter-summary").attr("data-filters", this.element.getAttribute("data-segment-filters").replaceAll("=>", ":"));
      $("#filter-summary").html("<div data-controller='general--render-filters' data-render-url='" + render_url + "' data-target='general--render-filters.filters' data-filters='" + this.element.getAttribute("data-segment-filters").replaceAll("=>", ":") + "'></div>");
      $("#segment").attr("data-value", segment);
      $("#segment_id").attr("data-value", segment_id);
      $.ajax({
        type: "GET",
        url: render_url,
        data: {segment: segment, id: segment_id, cache_key: cache_key},
        complete: function() {
          if (segment == "custom") {
            $("#delete-segment").show();
            $("#delete-segment").attr("data-segment-id", segment_id);
            $("#filter-name").html(segment_name);
            $("#update-segment").show();
          }
          $.fn.matchHeight._update(); // TRIGGER MATCH HEIGHT FOR FOOTER POSITIONING
          feather.replace(); // UPDATE ICONS
          $('[data-toggle="tooltip"]').tooltip(); // INITIALIZE TOOLTIP

        }
      })
    }
    $("body").removeClass("loading-page");
  }

  order_table(event){
    const field = event.currentTarget.getAttribute("data-field")
    const direction = event.currentTarget.getAttribute("data-direction")
    const render_url = this.element.getAttribute("data-render-url")
    let params_array = JSON.parse( $("#filter-summary").attr("data-filters") )
    $.ajax({
      type: "GET",
      url: render_url,
      data: {segment: "filter", params_array: params_array, cache_key: new Date(), field: field, direction: direction},
      complete: function() {
        $.fn.matchHeight._update(); // TRIGGER MATCH HEIGHT FOR FOOTER POSITIONING
        feather.replace(); // UPDATE ICONS
        $('[data-toggle="tooltip"]').tooltip(); // INITIALIZE TOOLTIP
        $("body").removeClass("loading-page");
        document.querySelectorAll(".direction-button").forEach(function(elem){
          elem.classList.remove("text-primary")
        })
        document.querySelector(".direction-button[data-field='" + field + "'][data-direction='" + direction + "']").classList.add("text-primary")
        document.querySelector("th[data-field='" + field + "']").setAttribute("data-direction", (direction == "DESC" ? "ASC" : "DESC"))
      }
    });
  }


  resetPage(){
    $(".segment").removeClass('active');
    $("#delete-segment").hide();
    $("#delete-segment").off('click');
    $("#trigger-save-modal").off('click');
    $("#update-segment-radio").hide();
    $("#filter-summary").html("");
    $("#render_block").html('<div class="spinner-border text-center text-primary m-t-100 m-b-100" role="status"><span class="sr-only">Loading...</span></div>');
  }

  segmentOnClick(event) {
    $("body").addClass("loading-page");
    this.resetPage();

    event.currentTarget.classList.add('active')
    let segment = event.currentTarget.getAttribute("data-segment")
    let segment_id = event.currentTarget.getAttribute("data-segment_id")
    let segment_name = event.currentTarget.getAttribute("data-segment-name")
    let stateObj = { id: segment_id };
    let render_url = this.element.getAttribute("data-render-url")
    let cache_key = this.element.getAttribute("data-cache-key")
    window.history.pushState(stateObj, segment, window.location.href.split('?segment_id=')[0] + "?segment_id=" + segment_id);
    let params_array = JSON.parse( event.currentTarget.getAttribute("data-segment-filters").replaceAll("=>", ":") )

    $(".segment-name").html( segment_name )
    $("#filter-summary").attr("data-filters", this.element.getAttribute("data-segment-filters").replaceAll("=>", ":"));
    $("#filter-summary").html("<div data-controller='general--render-filters' data-render-url='" + render_url + "' data-target='general--render-filters.filters' data-filters='" + event.currentTarget.getAttribute("data-segment-filters").replaceAll("=>", ":") + "' data-cache-key='" + cache_key + "'></div>");
    $("#segment").attr("data-value", segment);
    $("#segment_id").attr("data-value", segment_id);
    $.ajax({
      type: "GET",
      url: render_url,
      data: {segment: segment, id: segment_id, cache_key: cache_key},
      complete: function() {
        if (segment == "custom") {
          $("#delete-segment").show();
          $("#delete-segment").attr("data-segment-id", segment_id);
          $("#filter-name").html(segment_name);
          $("#update-segment").show();
        }
        $.fn.matchHeight._update(); // TRIGGER MATCH HEIGHT FOR FOOTER POSITIONING
        feather.replace(); // UPDATE ICONS
        $('[data-toggle="tooltip"]').tooltip(); // INITIALIZE TOOLTIP
        $("body").removeClass("loading-page");
      }
    })
  }

  addFilter(e) {
    e.preventDefault();
    $(".filter-dropdown-menu").hide();
    e.stopPropagation();
    $("#add-filter-menu").show();
    $('.filter-dropdown-menu').click(function(e) {
      e.stopPropagation();
    })
    function closeDropdowns() {
      $(".filter-dropdown-menu").hide();
      $(document.body).off('click');
    }
    $(document.body).click(closeDropdowns);
  }

  filterField(event) {
    $(".filter-dropdown-menu").hide();

    let search_field = event.currentTarget.getAttribute("data-search-field")
    let filter_type = event.currentTarget.getAttribute("data-filter-type")

    this.search_fieldTarget.setAttribute("data-value", search_field)
    this.filter_typeTarget.setAttribute("data-value", filter_type)

    if ( filter_type == "string") {
      $('#filter-string').show();
    } else if ( filter_type == "integer") {
      $('#filter-integer').show();
    } else if ( filter_type == "location") {
      $('#filter-location').show();
    } else if ( filter_type == "guest_tag") {
      $('#filter-guest_tag').show();
    } else if ( filter_type == "gender" ) {
      $('#filter-gender').show();
    } else if ( filter_type == "provider" ) {
      $('#filter-provider').show();
    } else if ( filter_type == "is_subscribed" ) {
      $('#filter-is_subscribed').show();
    } else if ( filter_type == "birthday") {
      $('#filter-birthday').show();
    } else if (filter_type == "blast" ) {
      $('#filter-blast').show();
    } else if (filter_type == "bounce-blast" ) {
      $('#filter-bounce-blast').show();
    } else if (filter_type == "complaint-blast" ) {
      $('#filter-complaint-blast').show();
    } else if (filter_type == "interaction" ) {
      $('#filter-interaction').show();
    } else if (filter_type == "bounce-interaction" ) {
      $('#filter-bounce-interaction').show();
    } else if (filter_type == "complaint-interaction" ) {
      $('#filter-complaint-interaction').show();
    } else if (filter_type == "engagement-integer") {
      $('#filter-engagement-integer').show();
    } else if (filter_type == "engagement"){
      $('#filter-engagement').show();
    } else if (filter_type == "review"){
      $('#filter-review').show();
    } else if (filter_type == "form"){
      $('#filter-form').show();
    } else if (filter_type == "sms_subscribed"){
      $('#filter-sms-subscribed').show();
    } else {
      $('#filter-date').show();
    }

  }

  queryType(event){

    let filter_type = this.filter_typeTarget.getAttribute("data-value")
    let search_field = this.search_fieldTarget.getAttribute("data-value")
    let render_url = this.element.getAttribute("data-render-url")

    $(".apply-filter a").css('cursor', 'not-allowed');
    $("#search_value").remove();
    if ( ["string", "integer", "date", "birthday", "engagement-integer"].includes(filter_type) && !["is unknown", "has any value"].includes( $(".dropdown-item [type=radio]:checked").val() ) ) {
      let input = document.createElement('input')
      let type = document.createAttribute('type')
      if (filter_type == "string") {
        type.value = "text"
      } else {
        type.value = "number"
      }
      input.setAttributeNode(type)
      let id = document.createAttribute('id')
      id.value = "search_value"
      input.setAttributeNode(id)
      let class_elem = document.createAttribute('class')
      class_elem.value = 'form-control'
      input.setAttributeNode(class_elem)
      let controller_attribute = document.createAttribute('data-controller')
      controller_attribute.value = "guest-database--filter-guests"
      input.setAttributeNode(controller_attribute)
      let action_attribute = document.createAttribute('data-action')
      action_attribute.value = "keyup->guest-database--filter-guests#enterFilter"
      input.setAttributeNode(action_attribute)
      let filter_type_node = document.createAttribute('data-filter-type')
      filter_type_node.value = filter_type
      input.setAttributeNode(filter_type_node)
      let search_field_node = document.createAttribute('data-search-field')
      search_field_node.value = search_field
      input.setAttributeNode(search_field_node)
      let data_render_url = document.createAttribute("data-render-url")
      data_render_url.value = render_url
      input.setAttributeNode(data_render_url)
      let cache_key = document.createAttribute("data-cache-key")
      cache_key.value = this.element.getAttribute("data-cache-key")
      input.setAttributeNode(cache_key)
      event.currentTarget.closest("label").appendChild(input)
    }
    if ($(".dropdown-item [type=radio]:checked").length > 0) {
      $(".apply-filter a").css('cursor', 'pointer');
      //$(".apply-filter").click(applyFilter);
    }
  }

  enterFilter() {
    //event.stopPropagation()
    const key = event.which
    //console.log(key)
    if (key === 13) {
      event.stopImmediatePropagation();
      if (!this.validate_value()){
        return
      }
      //console.log("enter pressed")
      $("body").addClass("loading-page");
      const search_field = event.currentTarget.getAttribute("data-search-field")
      const filter_type = event.currentTarget.getAttribute("data-filter-type")
      let params_array = JSON.parse( $("#filter-summary").attr("data-filters") )
      let search_value
      let query_type
      let render_url = this.element.getAttribute("data-render-url")
      let cache_key = this.element.getAttribute("data-cache-key")
      $("#trigger-save-modal").off('click');
      $(".filter-dropdown-menu").hide();
      $("#render_block").html('<div class="spinner-border text-center text-primary m-t-100 m-b-100" role="status"><span class="sr-only">Loading...</span></div>');
      if ( filter_type == "string") {
        query_type = $("#filter-string [type=radio]:checked").val();
        if ( !["is unknown", "has any value"].includes( query_type ) ) {
          search_value = event.currentTarget.value
        } else {
          search_value = query_type;
        }
      } else if ( filter_type == "integer") {
        query_type = $("#filter-integer [type=radio]:checked").val();
        if ( !["is unknown", "has any value"].includes( query_type ) ) {
          search_value = event.currentTarget.value
        } else {
          search_value = query_type;
        }
      } else if ( filter_type == "engagement-integer"){
        query_type = $("#filter-engagement-integer [type=radio]:checked").val();
        search_value = event.currentTarget.value
      } else if ( filter_type == "location") {
        query_type = $("#filter-location [type=radio]:checked").val();
        search_value = query_type;
      } else if ( filter_type == "guest_tag" ) {
        query_type = $("#filter-guest_tag [type=radio]:checked").val();
        search_value = query_type;
      } else if ( filter_type == "gender" ) {
        query_type = $("#filter-gender [type=radio]:checked").val();
        search_value = query_type;
      } else if ( filter_type == "provider" ) {
        query_type = $("#filter-provider [type=radio]:checked").val();
        search_value = query_type;
      } else if ( filter_type == "is_subscribed" ) {
        query_type = $("#filter-is_subscribed [type=radio]:checked").val();
        search_value = query_type;
      } else if ( filter_type == "birthday" ) {
        query_type = $("#filter-birthday [type=radio]:checked").val();
        if ( !["is unknown", "has any value"].includes( query_type ) ) {
          search_value = $("#search_value").val();
        } else {
          search_value = query_type;
        }
      } else {
        query_type = $("#filter-date [type=radio]:checked").val();
        search_value = $("#search_value").val();
      }
      //console.log(render_url)
      let search_params = {filter_type:filter_type, search_field:search_field, query_type:query_type, search_value:search_value};
      params_array.push(search_params);
      $("#filter-summary").attr("data-filters", JSON.stringify(params_array));
      $("#filter-summary").html("<div data-controller='general--render-filters' data-render-url='" + render_url + "' data-target='general--render-filters.filters' data-filters='" + JSON.stringify(params_array) + "' data-cache-key='" + cache_key + "'></div>");
      $.ajax({
        type: "GET",
        url: render_url,
        data: {segment: "filter", params_array: params_array, cache_key: cache_key},
        complete: function() {
          $.fn.matchHeight._update(); // TRIGGER MATCH HEIGHT FOR FOOTER POSITIONING
          feather.replace(); // UPDATE ICONS
          $('[data-toggle="tooltip"]').tooltip(); // INITIALIZE TOOLTIP
          let segment = $("#segment").attr("data-value");
          let segment_name = $("#segment-name")[0].innerText;
          if (segment == "custom") {
            $("#filter-name").html(segment_name);
            $("#update-segment").show();
          }
          $("body").removeClass("loading-page");
        }
      });
      $("#search_value").remove();
    }

  }

  valid_dropdown_selection(){
    if (document.querySelectorAll(".dropdown-item input[type='radio']:checked").length > 0){
      return true
    } else {
      return false
    }
  }
  validate_value(){
    if (!document.getElementById("search_value") || document.getElementById("search_value").value.length > 0 ){
      return true
    } else {
      return false
    }
  }

  applyFilter() {
    if (this.valid_dropdown_selection()){
      if (!this.validate_value()){
        return
      }
      $("body").addClass("loading-page");
      let filter_type = this.filter_typeTarget.getAttribute("data-value")
      let search_field = this.search_fieldTarget.getAttribute("data-value")
      let params_array = JSON.parse( $("#filter-summary").attr("data-filters") )
      let search_value
      let query_type
      let render_url = this.element.getAttribute("data-render-url")
      let cache_key = this.element.getAttribute("data-cache-key")
      let mode = event.currentTarget.dataset.filterMode
      $("#trigger-save-modal").off('click');
      $(".filter-dropdown-menu").hide();
      $("#render_block").html('<div class="spinner-border text-center text-primary m-t-100 m-b-100" role="status"><span class="sr-only">Loading...</span></div>');
      if ( filter_type == "string") {
        query_type = $("#filter-string [type=radio]:checked").val();
        if ( !["is unknown", "has any value"].includes( $(".dropdown-item [type=radio]:checked").val() ) ) {
          search_value = $("#search_value").val();
        } else {
          search_value = query_type;
        }
      } else if ( filter_type == "integer") {
        query_type = $("#filter-integer [type=radio]:checked").val();
        if ( !["is unknown", "has any value"].includes( $(".dropdown-item [type=radio]:checked").val() ) ) {
          search_value = $("#search_value").val();
        } else {
          search_value = query_type;
        }
      } else if (filter_type == "engagement-integer") {
        query_type = $("#filter-engagement-integer [type=radio]:checked").val();
        if ( !["is unknown", "has any value"].includes( $(".dropdown-item [type=radio]:checked").val() ) ) {
          search_value = $("#search_value").val();
        } else {
          search_value = query_type;
        }
      } else if ( filter_type == "location") {
        query_type = $("#filter-location [type=radio]:checked").val();
        search_value = query_type;
      } else if ( filter_type == "form") {
        query_type = $("#filter-form [type=radio]:checked").val();
        search_value = query_type;
      } else if ( filter_type == "review" ){
        query_type = $("#filter-review [type=radio]:checked").val();
        search_value = query_type;
      } else if ( filter_type == "guest_tag" ) {
        query_type = $("#filter-guest_tag [type=radio]:checked").val();
        search_value = query_type;
      } else if ( filter_type == "gender" ) {
        query_type = $("#filter-gender [type=radio]:checked").val();
        search_value = query_type;
      } else if ( filter_type == "provider" ) {
        query_type = $("#filter-provider [type=radio]:checked").val();
        search_value = query_type;
      } else if ( filter_type == "is_subscribed" ) {
        query_type = $("#filter-is_subscribed [type=radio]:checked").val();
        search_value = query_type;
      } else if ( filter_type == "sms_subscribed" ) {
        query_type = $("#filter-sms-subscribed [type=radio]:checked").val();
        search_value = query_type;
      } else if ( filter_type == "birthday" ) {
        query_type = $("#filter-birthday [type=radio]:checked").val();
        if ( !["is unknown", "has any value"].includes( $(".dropdown-item [type=radio]:checked").val() ) ) {
          search_value = $("#search_value").val();
        } else {
          search_value = query_type;
        }
      } else if ( filter_type == "blast" ) {
        query_type = $("#filter-blast [type=radio]:checked").val();
        search_value = query_type;
      } else if ( filter_type == "bounce-blast" ) {
        query_type = $("#filter-bounce-blast [type=radio]:checked").val();
        search_value = query_type;
      } else if ( filter_type == "complaint-blast" ) {
        query_type = $("#filter-complaint-blast [type=radio]:checked").val();
        search_value = query_type;
      } else if ( filter_type == "interaction" ) {
        query_type = $("#filter-interaction [type=radio]:checked").val();
        search_value = query_type;
      } else if ( filter_type == "bounce-interaction" ) {
        query_type = $("#filter-bounce-interaction [type=radio]:checked").val();
        search_value = query_type;
      } else if ( filter_type == "complaint-interaction" ) {
        query_type = $("#filter-complaint-interaction [type=radio]:checked").val();
        search_value = query_type;
      } else {
        query_type = $("#filter-date [type=radio]:checked").val();
        search_value = $("#search_value").val();
      }

      let search_params = {filter_type:filter_type, search_field:search_field, query_type:query_type, search_value:search_value, mode: mode};
      params_array.push(search_params);

      $("#filter-summary").attr("data-filters", JSON.stringify(params_array));
      $("#filter-summary").html("<div data-controller='general--render-filters' data-render-url='" + render_url + "' data-target='general--render-filters.filters' data-filters='" + JSON.stringify(params_array) + "' data-cache-key='" + cache_key + "'></div>");
      $.ajax({
        type: "GET",
        url: render_url,
        data: {segment: "filter", params_array: params_array, cache_key: cache_key},
        complete: function() {
          $.fn.matchHeight._update(); // TRIGGER MATCH HEIGHT FOR FOOTER POSITIONING
          feather.replace(); // UPDATE ICONS
          $('[data-toggle="tooltip"]').tooltip(); // INITIALIZE TOOLTIP
          let segment = $("#segment").attr("data-value");
          let segment_name = $("#segment-name")[0].innerText;
          if (segment == "custom") {
            $("#filter-name").html(segment_name);
            $("#update-segment").show();
          }
          $("body").removeClass("loading-page");
        }
      });
      $("#search_value").remove();
      //Enable below code to fix state to filters.
      //let stateObj = { id: params_array };
      //window.history.pushState(stateObj, params_array, "/app/" + $("body").attr("data-account-id") + "/guests?filters=" + JSON.stringify(params_array));
    }

  };

  deleteButton(event) {
    event.stopPropagation()
    event.preventDefault()
    swal({
      title: 'Are you sure?',
      text: "Removing cannot be undone",
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete this segment!',
      cancelButtonText: 'Cancel',
      confirmButtonClass: 'btn btn-lg btn-danger waves-effect waves-light m-t-20',
      cancelButtonClass: 'btn btn-lg btn-transparent waves-effect waves-light m-l-10 m-t-20',
      buttonsStyling: false
    }).then(function () {
      $("body").addClass("loading-page");
      let segment_id = $("#delete-segment").attr("data-segment-id")
      $.ajax({
        type: "delete",
        url: `/app/${$("body").attr("data-account-id")}/guests/delete_segment`,
        data: {segment_id: segment_id},
      });
      $('.segment[data-segment_id="' + segment_id + '"]').remove();
      $(".segment").first().click();
      $("body").removeClass("loading-page");
    }, function (dismiss) {
      if (dismiss === 'cancel') {
        // window.location.href = link;
      }
    })
  }

  removeFilter(event){
    $("body").addClass("loading-page");
    $("#trigger-save-modal").off('click');
    $("#render_block").html('<div class="spinner-border text-center text-primary m-t-100 m-b-100" role="status"><span class="sr-only">Loading...</span></div>');
    let index = Number(event.currentTarget.getAttribute("data-array-position"));
    let params_array = JSON.parse( $("#filter-summary").attr("data-filters") )
    let render_url = event.currentTarget.getAttribute("data-render-url")
    let cache_key = this.element.getAttribute("data-cache-key")
    params_array.splice(index, 1);
    $("#filter-summary").attr("data-filters", JSON.stringify(params_array));
    $("#filter-summary").html("<div data-controller='general--render-filters' data-render-url='" + render_url + "' data-target='general--render-filters.filters' data-filters='" + JSON.stringify(params_array) + "' data-cache-key='" + cache_key + "'></div>");
    $.ajax({
      type: "GET",
      url: render_url,
      data: {segment: "filter", params_array: params_array, cache_key: cache_key},
      complete: function() {
        $.fn.matchHeight._update(); // TRIGGER MATCH HEIGHT FOR FOOTER POSITIONING
        feather.replace(); // UPDATE ICONS
        $('[data-toggle="tooltip"]').tooltip(); // INITIALIZE TOOLTIP
        let segment = $("#segment").attr("data-value");
        let segment_name = $("#segment-name")[0].innerText;
        if (segment == "custom") {
          $("#filter-name").html(segment_name);
          $("#update-segment").show();
        }
        $("body").removeClass("loading-page");
      }
    });
  }

  displayBulkActions(event) {
    if(!event.target.checked){
      $("#checkbox_all")[0].checked = false
    }

    if ($("[type=checkbox]:checked").length > 0) {
      $("#bulk_actions").show();
    } else {
      $("#bulk_actions").hide();
    }
  }

  checkAll(){
    if ( $("#checkbox_all")[0].checked ) {
      $.each( $(".individual-checkbox [type=checkbox]"), function(index) {
        $(".individual-checkbox [type=checkbox]")[index].checked = true;
        $("#bulk_actions").show();
      })
      //$(".individual-checkbox [type=checkbox]").attr('checked', true);
    } else {
      $.each( $(".individual-checkbox [type=checkbox]"), function(index) {
        $(".individual-checkbox [type=checkbox]")[index].checked = false;
        $("#bulk_actions").hide();
      })
      //(".individual-checkbox [type=checkbox]").attr('checked', false);
      //console.log("false");
    }
  }

  tagOnClick() {
    $(".tag-option").removeClass('selected-tag');
    event.currentTarget.classList.add('selected-tag')
    $("#submit_tags").attr('disabled', false);
  }

  removeTagOnClick() {
    $(".remove-tag-option").removeClass('selected-removal-tag');
    event.currentTarget.classList.add('selected-removal-tag')
    $("#submit_tag_removals").attr('disabled', false);
  }

  removeHuman(){
    event.stopPropagation()
    event.preventDefault()
    var counter = 0
    const counterElement = document.querySelector('.guest-table-header-count').innerText.replace(/,/g, '');
    if(!isNaN(counterElement)) {
      counter = parseInt(counterElement)
    }
    swal({
      title: 'Are you sure?',
      text: "Archiving will unsubscribe this guest from all communications and could impact your billing",
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, archive these guests!',
      cancelButtonText: 'Cancel',
      confirmButtonClass: 'btn btn-lg btn-danger waves-effect waves-light m-t-20',
      cancelButtonClass: 'btn btn-lg btn-transparent waves-effect waves-light m-l-10 m-t-20',
      buttonsStyling: false
    }).then(function () {
      $("body").addClass("loading-page");
      if ( $("#checkbox_all")[0].checked ) {
        let filters = $("#checkbox_all").val()
        $.ajax({
          type: "PATCH",
          url: `/app/${$("body").attr("data-account-id")}/guests/bulk_remove`,
          data: {remote: true, filters: filters},
          complete: function() {
            setTimeout(()=>{
              $.ajax({
                type: "GET",
                url: "/app/" + $("body").attr("data-account-id") + "/guests/render_segment_menu.js",
                data: {render_url: "/app/" + $("body").attr("data-account-id") + "/guests/render_guest_table.js", cache_key: '', segments_update: true, selected_segment_id: 'first_default'},
              });
            },1000)
            counter = 0
          }
        })
      } else {
        $.each($(".individual-checkbox [type=checkbox]:checked"), function(index) {
          var human_id = $(".individual-checkbox [type=checkbox]:checked")[index].value;
          $.ajax({
            type: "PATCH",
            url: `/app/${$("body").attr("data-account-id")}/guests/remove/${human_id}`,
            data: {remote: true},
          })
          if(counter > 0){
            counter = counter - 1
          }
        })
        setTimeout(()=>{
          $.ajax({
            type: "GET",
            url: "/app/" + $("body").attr("data-account-id") + "/guests/render_segment_menu.js",
            data: {render_url: "/app/" + $("body").attr("data-account-id") + "/guests/render_guest_table.js", cache_key: '', segments_update: true, selected_segment_id: 'first_default'},
          });
        }, 500)
      }

      document.querySelector('.guest-table-header-count').innerText = counter
      $(".individual-checkbox [type=checkbox]:checked").closest('tr').remove();
      toastr.success('These guests will be remove in few seconds');
      $("body").removeClass("loading-page");

    }, function (dismiss) {
      if (dismiss === 'cancel') {
        // window.location.href = link;
      }
    });


  }

  revertHuman(){
    event.stopPropagation()
    event.preventDefault()
    var counter = 0
    const counterElement = document.querySelector('.guest-table-header-count').innerText.replace(/,/g, '');
    if(!isNaN(counterElement)) {
      counter = parseInt(counterElement)
    }
    swal({
      title: 'Are you sure?',
      text: "Unarchiving this guest will allow you to communicate with them again and could impact your billing",
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, Unarchive these guests!',
      cancelButtonText: 'Cancel',
      confirmButtonClass: 'btn btn-lg btn-danger waves-effect waves-light m-t-20',
      cancelButtonClass: 'btn btn-lg btn-transparent waves-effect waves-light m-l-10 m-t-20',
      buttonsStyling: false
    }).then(function () {
      $("body").addClass("loading-page");
      if ( $("#checkbox_all")[0].checked ) {
        let filters = $("#checkbox_all").val()
        $.ajax({
          type: "PATCH",
          url: `/app/${$("body").attr("data-account-id")}/guests/bulk_revert`,
          data: {remote: true, filters: filters},
          complete: function() {
            setTimeout(()=> {
              $.ajax({
                type: "GET",
                url: "/app/" + $("body").attr("data-account-id") + "/guests/render_segment_menu.js",
                data: {render_url: "/app/" + $("body").attr("data-account-id") + "/guests/render_guest_table.js", cache_key: '', segments_update: true, selected_segment_id: 'first_default'},
              });
              $('.guest-table-header-count').html(`0 guests`)
            }, 2000)
            counter = 0
          } 
        })
      } else {
        $.each($(".individual-checkbox [type=checkbox]:checked"), function(index) {
          var human_id = $(".individual-checkbox [type=checkbox]:checked")[index].value;
          $.ajax({
            type: "PATCH",
            url: `/app/${$("body").attr("data-account-id")}/guests/revert/${human_id}`,
            data: {remote: true}
          })
          if(counter > 0){
            counter = counter - 1
          }
        })
        setTimeout(()=>{
          $.ajax({
            type: "GET",
            url: "/app/" + $("body").attr("data-account-id") + "/guests/render_segment_menu.js",
            data: {render_url: "/app/" + $("body").attr("data-account-id") + "/guests/render_guest_table.js", cache_key: '', segments_update: true, selected_segment_id: 'first_default'},
          });
        },500)
      }

      document.querySelector('.guest-table-header-count').innerText = counter

      $(".individual-checkbox [type=checkbox]:checked").closest('tr').remove();
      toastr.success('These guests will be reverted in a while.');
      $("body").removeClass("loading-page");

    }, function (dismiss) {
      if (dismiss === 'cancel') {
        // window.location.href = link;
      }
    });


  }

  // reloadSegmentMenu(){
  //   console.log('reloading')
  //   $.ajax({
  //     type: "GET",
  //     url: "/app/" + $("body").attr("data-account-id") + "/guests/render_segment_menu.js",
  //     data: {render_url: "/app/" + $("body").attr("data-account-id") + "/guests/render_guest_table.js", cache_key: ''},
  //     complete: function() {
  //       console.log('reloaded')
  //     }
  //   });
  // }

  blockHuman(){

    event.stopPropagation()
    event.preventDefault()

    swal({
      title: 'Are you sure?',
      text: "Blocking will prevent this guest from connecting",
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, block these guests!',
      cancelButtonText: 'Cancel',
      confirmButtonClass: 'btn btn-lg btn-danger waves-effect waves-light m-t-20',
      cancelButtonClass: 'btn btn-lg btn-transparent waves-effect waves-light m-l-10 m-t-20',
      buttonsStyling: false
    }).then(function () {
      $("body").addClass("loading-page");

      if ( $("#checkbox_all")[0].checked ) {
        let filters = $("#checkbox_all").val()
        $.ajax({
          type: "PATCH",
          url: `/app/${$("body").attr("data-account-id")}/guests/bulk_block`,
          data: {remote: true, filters: filters},
          complete: function() {
            $.ajax({
              type: "GET",
              url: "/app/" + $("body").attr("data-account-id") + "/guests/render_segment_menu.js",
              data: {render_url: "/app/" + $("body").attr("data-account-id") + "/guests/render_guest_table.js", cache_key: '', segments_update: true, selected_segment_id: 'first_default'},
            });
          }
        })
      } else {
        $.each($(".individual-checkbox [type=checkbox]:checked"), function(index) {
          var human_id = $(".individual-checkbox [type=checkbox]:checked")[index].value;
          $.ajax({
            type: "PATCH",
            url: `/app/${$("body").attr("data-account-id")}/guests/block/${human_id}`,
            data: {remote: true},
            complete: function() {
              $.ajax({
                type: "GET",
                url: "/app/" + $("body").attr("data-account-id") + "/guests/render_segment_menu.js",
                data: {render_url: "/app/" + $("body").attr("data-account-id") + "/guests/render_guest_table.js", cache_key: '', segments_update: true, selected_segment_id: 'first_default'},
              });
            } 
          })
        })
      }

      $(".individual-checkbox [type=checkbox]:checked").closest('tr').remove();
      toastr.success('These guests have been blocked.');
      $("body").removeClass("loading-page");
    }, function (dismiss) {
      if (dismiss === 'cancel') {
        // window.location.href = link;
      }
    });

  }

  unsubscribeHuman(){

    event.stopPropagation()
    event.preventDefault()

    swal({
      title: 'Are you sure?',
      text: "This will unsubscribe this guest from receiving emails",
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, unsubscribe these guests!',
      cancelButtonText: 'Cancel',
      confirmButtonClass: 'btn btn-lg btn-danger waves-effect waves-light m-t-20',
      cancelButtonClass: 'btn btn-lg btn-transparent waves-effect waves-light m-l-10 m-t-20',
      buttonsStyling: false
    }).then(function () {
      $("body").addClass("loading-page");

      if ( $("#checkbox_all")[0].checked ) {
        let filters = $("#checkbox_all").val()
        $.ajax({
          type: "PATCH",
          url: `/app/${$("body").attr("data-account-id")}/guests/bulk_unsubscribe`,
          data: {remote: true, filters: filters},
        })
      } else {
        $.each($(".individual-checkbox [type=checkbox]:checked"), function(index) {
          var human_id = $(".individual-checkbox [type=checkbox]:checked")[index].value;
          $.ajax({
            type: "PATCH",
            url: `/app/${$("body").attr("data-account-id")}/guests/unsubscribe/${human_id}`,
            data: {remote: true},
          })
        })
      }


      toastr.success('These guests have been unsubscribed.');
      $("body").removeClass("loading-page");
    }, function (dismiss) {
      if (dismiss === 'cancel') {
        // window.location.href = link;
      }
    });

  }

  forgetDevise(){

    event.stopPropagation()
    event.preventDefault()

    swal({
      title: 'Are you sure?',
      text: "This force all your selected guests to sign into your guest wifi again",
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, forget devises for these guests!',
      cancelButtonText: 'Cancel',
      confirmButtonClass: 'btn btn-lg btn-danger waves-effect waves-light m-t-20',
      cancelButtonClass: 'btn btn-lg btn-transparent waves-effect waves-light m-l-10 m-t-20',
      buttonsStyling: false
    }).then(function () {
      $("body").addClass("loading-page");

      if ( $("#checkbox_all")[0].checked ) {
        let filters = $("#checkbox_all").val()
        $.ajax({
          type: "POST",
          url: `/app/${$("body").attr("data-account-id")}/guest/bulk_forget_all_devices`,
          data: {remote: true, filters: filters},
        })
      } else {
        let guest_ids = []
        $.each($(".individual-checkbox [type=checkbox]:checked"), function(index) {
          guest_ids.push($(".individual-checkbox [type=checkbox]:checked")[index].value)
        })
        $.ajax({
          type: "POST",
          url: `/app/${$("body").attr("data-account-id")}/guest/forget_all_devices`,
          data: {remote: true, guest_ids: guest_ids.join(',')},
        })
      }


      toastr.success('The devises for these guests have been forgotten.');
      $("body").removeClass("loading-page");
    }, function (dismiss) {
      if (dismiss === 'cancel') {
        // window.location.href = link;
      }
    });

  }

  saveSegementClick(){
    event.stopPropagation()
    $("#save-segment-modal").modal('toggle');
  }

  newToUpdate(){
    if ( $("#save-segment-modal [type=radio]:checked").val() == "update exisiting segment" ) {
      $("#update-segment-button").show();
      $("#save-segment-button").hide();
    } else {
      $("#update-segment-button").hide();
      $("#save-segment-button").show();
    }
  }

  updateSegment(){
    $("body").addClass("loading-page");
    $("#save-segment-modal").modal('hide');
    $('body').removeClass('modal-open');
    $('.modal-backdrop').remove();

    let params_array = JSON.parse( $("#filter-summary").attr("data-filters") )
    let count = event.currentTarget.getAttribute("data-humans-count")
    let segment_id = $("#segment_id").attr("data-value");


    $('[data-segment_id="' + segment_id + '"]').children().children('span')[1].innerText = count;
    $('[data-segment_id="' + segment_id + '"]').attr('data-segment-filters', $("#filter-summary").attr("data-filters"));

    $.ajax({
      type: "POST",
      url: `/app/${$("body").attr("data-account-id")}/guests/update_segment`,
      data: {id: segment_id, filters: params_array, count: count},
      success: function() {
        toastr.success('This segment has been updated.');
        $("body").removeClass("loading-page");
        //resetPage();
        //$("#delete-segment").click(deleteButton);
        //$('.segment[data-segment_id="' + segment_id + '"]').addClass('active');

      }
    });

  }

  validate_segment_name(event){
    let name = event.currentTarget.value
    if (name.trim().length > 0){
      document.getElementById("save-segment-button").removeAttribute("disabled")
    } else {
      document.getElementById("save-segment-button").setAttribute("disabled", true)
    }
  }

  createSegment(){
    $("body").addClass("loading-page");
    $("#save-segment-modal").modal('hide');
    $('body').removeClass('modal-open');
    $('.modal-backdrop').remove();
    $('.segment').removeClass('active');

    let name = document.getElementById("segment-name-input").value
    let params_array = JSON.parse( $("#filter-summary").attr("data-filters") )
    let count = event.currentTarget.getAttribute("data-humans-count")

    $.ajax({
      type: "POST",
      url: `/app/${$("body").attr("data-account-id")}/guests/save_segment`,
      data: {name: name, filters: params_array, count: count},
      success: function(res) {
        let html = "<div class='segment' style='text-align: left !important; ' data-controller='guest-database--filter-guests' data-segment='custom' data-segment_id='" + res[0]['id'] + "' data-action='click->guest-database--filter-guests#segmentOnClick' data-segment-filters='" + JSON.stringify(params_array) + "' data-segment-name='" + name + "' data-segment-selected='true' data-render-url='guests/render_guest_table.js'><h4 class='header-title'><span>" + name + "</span><span class='pull-right muted-text'>" + count + "</span></h4></div>"
        $("#segment-menu").append(html);
        $("body").removeClass("loading-page");
      }
    });

  }

  submitTagRemoval() {
    $("body").addClass("loading-page");
    let guest_ids = []
    let tag_id = $(".selected-removal-tag").attr("data-tag-id");

    if ( $("#checkbox_all")[0].checked ) {
      let filters = $("#checkbox_all").val()
      $.ajax({
        type: "POST",
        url: `/app/${$("body").attr("data-account-id")}/guests/bulk_remove_tag_users`,
        data: {new_tag: false, filters: filters, tag_id: tag_id},
      })
    } else {

      $.each($(".individual-checkbox [type=checkbox]:checked"), function(index) {
        guest_ids.push( $(".individual-checkbox [type=checkbox]:checked")[index].value );
      })
      $.ajax({
        type: "POST",
        url: `/app/${$("body").attr("data-account-id")}/guests/remove_tag_users`,
        data: {new_tag: false, guest_ids: guest_ids, tag_id: tag_id},
      })
    }




    $("#remove-tags-modal").modal('hide');
    toastr.success('The tag has been removed from these guests.');
    $("#submit_tag_removals").attr('disabled', true);
    let tags = JSON.parse(this.element.getAttribute("data-tag-list"))
    let html = ""
    $.each(tags, function(index) {
      html += "<div class='tag-option' data-tag-id='" + tags[index][0] + "' data-controller='guest-database--filter-guests' data-action='click->guest-database--filter-guests#tagOnClick'>" + tags[index][1] + "</div>"
    })
    $("#remove_tag_summary").html(html);
    $("body").removeClass("loading-page");
  }

  submitTag(){
    $("body").addClass("loading-page");
    let selection = $(".selected-tag");
    let guest_ids = [];
    let data
    let tag_name
    let tag_id
    let tags = JSON.parse(this.element.getAttribute("data-tag-list"))
    if (selection.hasClass("new-tag")) {
      tag_name = $("#tag_search_field").val();
      data = {new_tag: true, guest_ids: guest_ids, tag_name: tag_name};
    } else {
      tag_id = $(".selected-tag").attr("data-tag-id");
      data = {new_tag: false, guest_ids: guest_ids, tag_id: tag_id};
    }

    if ( $("#checkbox_all")[0].checked ) {
      let filters = $("#checkbox_all").val()
      data["filters"] = filters
      console.log(filters)
      console.log(data)
      $.ajax({
        type: "POST",
        url: `/app/${$("body").attr("data-account-id")}/guests/bulk_tag_users`,
        data: data,
        cache: false,
        success: function(res) {
          if (selection.hasClass("new-tag")) {
            let html = ""
            let new_tag = [Number(res[0]['tag_id']), tag_name]
            tags.push(new_tag)
            $("#tag_summary").html("");
            $.each(tags, function(index) {
              html += "<div class='tag-option' data-tag-id='" + tags[index][0] + "' data-controller='guest-database--filter-guests' data-action='click->guest-database--filter-guests#tagOnClick'>" + tags[index][1] + "</div>"
            })
            $("#tag_summary").html(html);
          }
        }
      })
    } else {
      $.each($(".individual-checkbox [type=checkbox]:checked"), function(index) {
        guest_ids.push( $(".individual-checkbox [type=checkbox]:checked")[index].value );
      })
      $.ajax({
        type: "POST",
        url: `/app/${$("body").attr("data-account-id")}/guests/tag_users`,
        data: data,
        cache: false,
        success: function(res) {
          if (selection.hasClass("new-tag")) {
            let html = ""
            let new_tag = [Number(res[0]['tag_id']), tag_name]
            tags.push(new_tag)
            $("#tag_summary").html("");
            $.each(tags, function(index) {
              html += "<div class='tag-option' data-tag-id='" + tags[index][0] + "' data-controller='guest-database--filter-guests' data-action='click->guest-database--filter-guests#tagOnClick'>" + tags[index][1] + "</div>"
            })
            $("#tag_summary").html(html);
          }
        }
      })
    }

    $("#add-tags-modal").modal('hide');
    toastr.success('These guests have been tagged.');
    $("#tag_search_field").val('');
    $("#submit_tags").attr('disabled', true);
    $("body").removeClass("loading-page");
  }

  searchRemoveTags(){
    let matches = new Array();
    let tags = JSON.parse(this.element.getAttribute("data-tag-list"))
    $.each(tags, function(index) {
      if (tags[index][1].includes( $("#remove_tag_search_field").val() )) {
        matches.push(tags[index]);
      }
    })

    if (matches.length > 0 || $("#remove_tag_search_field").val().length < 1 ) {
      let html = "";
      $.each(matches, function(index) {
        html += "<div class='remove-tag-option' data-tag-id='" + matches[index][0] + "' data-controller='guest-database--filter-guests' data-action='click->guest-database--filter-guests#removeTagOnClick'>" + matches[index][1] + "</div>";
      })
      $("#remove_tag_summary").html(html);
    } else {
      $("#remove_tag_summary").html("<div class='no-result text-center'>Could not find tag: '" + $("#remove_tag_search_field").val() + "'</div>" );
      feather.replace(); // UPDATE ICONS
    }
    if ( $(".selected-removal-tag").length < 1 ) {
      $("#submit_tags").attr('disabled', true);
    }
  }

  searchTags() {
    let matches = new Array();
    let tags = JSON.parse(this.element.getAttribute("data-tag-list"))
    $.each(tags, function(index) {
      if (tags[index][1].includes( $("#tag_search_field").val() )) {
        matches.push(tags[index]);
      }
    })

    if (matches.length > 0 || $("#tag_search_field").val().length < 1 ) {
      let html = "";
      $.each(matches, function(index) {
        html += "<div class='tag-option' data-tag-id='" + matches[index][0] + "' data-controller='guest-database--filter-guests' data-action='click->guest-database--filter-guests#tagOnClick'>" + matches[index][1] + "</div>";
      })
      $("#tag_summary").html(html);
    } else {
      $("#tag_summary").html("<div class='tag-option new-tag' data-controller='guest-database--filter-guests' data-action='click->guest-database--filter-guests#tagOnClick'><i data-feather='tag' stroke-width='2' width='14' height='14' class='m-r-5' style='vertical-align:middle;position:relative;top:-1px;'></i> Create new tag: '" + $("#tag_search_field").val() + "'</div>" );
      feather.replace(); // UPDATE ICONS
    }
    if ( $(".selected-tag").length < 1 ) {
      $("#submit_tags").attr('disabled', true);
    }
  }

  setTigger(){
    let interaction_id = $("#interaction_id").attr("data-params")
    let params_array = JSON.parse( $("#filter-summary").attr("data-filters") )
    $.ajax({
      type: "POST",
      url: "/app/" + $("body").attr("data-account-id") + "/interaction/update_trigger",
      data: {id: interaction_id, filters: params_array},
    });
    //$('form.edit_interaction #ref').val($(this).data('ref-url'));
    //$('form.edit_interaction').submit();
  }
}
