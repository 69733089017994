import { Controller } from "stimulus"
import Rails from "@rails/ujs";

let swalRemoveConfirmed = false

export default class extends Controller {
  static targets = []

  initialize(){
    //
  }

  deleteBlast(event){
    if(!swalRemoveConfirmed){
      event.stopPropagation();
      event.preventDefault();
      const id = event.currentTarget.getAttribute('data-id');

      swal({
          title: 'Are you sure?',
          text: "Removing cannot be undone",
          type: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, remove it!',
          allowOutsideClick: true,
          cancelButtonText: 'Cancel',
          confirmButtonClass: 'btn btn-lg btn-danger waves-effect waves-light m-t-20',
          cancelButtonClass: 'btn btn-lg btn-transparent waves-effect waves-light m-l-10 m-t-20',
          buttonsStyling: false
      }).then(function () {
        swalRemoveConfirmed = true;
        $(".remove-trigger-" + id).trigger("click");
      }, function (dismiss) {
          if (dismiss === 'cancel') {
            // window.location.href = link;
          }
      });
    };
  }

}
